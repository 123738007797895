<template>
  <div class="flex space-x-8 md:space-x-0">
    <nuxt-link
      to="https://instagram.com/fotostorie.no"
      alt="Fotostorie er på Instagram"
      aria-label="Fotostorie er på Instagram"
      class="text-gray-900 duration-200 hover:text-primary"
    >
      <UIcon name="teenyicons:instagram-outline" /> 
    </nuxt-link>
    <nuxt-link
      to="https://facebook.com/fotostorie.no"
      alt="Få siste oppdateringer om Fotostorie på Facebook"
      aria-label="Få siste oppdateringer om Fotostorie på Facebook"
      class="ml-5 text-gray-900 duration-200 hover:text-primary"
    >
      <UIcon name="teenyicons:facebook-outline" />
    </nuxt-link>
    <nuxt-link
      href="https://pinterest.com/Fotostorie"
      alt="Tag bilder fra Fotostorie med Pinterest når du skal planlegge en anledning"
      aria-label="Tag bilder fra Fotostorie med Pinterest når du skal planlegge en anledning"
      class="ml-5 text-gray-900 duration-200 hover:text-primary"
    >
      <UIcon name="teenyicons:pinterest-outline" />
    </nuxt-link>
  </div>
</template>
