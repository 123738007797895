<script setup lang="ts">
const mainStore = useMainStore()
const { services } = storeToRefs(mainStore)
const { links } = useNav()
const emits = defineEmits<{
  close: [];
}>()
</script>

<template>
  <UDrawer title="Meny" description="navigation" @close="emits('close')">
    <UButton variant="soft" color="primary" icon="i-heroicons-bars-2-20-solid" aria-label="Meny" />
    <template #header>
      <span></span>
    </template>
    <template #body>
      <ul class="p-8 relative flex flex-col space-y-6">
        <li v-for="item in links" :key="`header_nav_mobile_${item.slug}`" class="text-center">
          <nuxt-link :to="item.slug" class="text-xl font-semibold items-center hover:text-primary-400"
            @click="emits('close')">
            {{ item.title }}
          </nuxt-link>
        </li>
      </ul>
      <div class="flex justify-center pt-6 pb-24">
        <SoMe />
      </div>
    </template>
  </UDrawer>
</template>
