import type { NavItem } from "~~/types"

export const useNav = () => {
  const route = useRoute()
  const items = ref([
    { title: 'Hjem', slug: '/', list: 'l' },
    { title: 'Tjenester', slug: '/tjenester', list: 'l'},
    { title: 'Blogg', slug: '/blogg', list: 'l' },
    { title: 'Spørsmål', slug: '/faq', list: 'r' },
    { title: 'Om', slug: '/om', list: 'r' },
    { title: 'Kontakt', slug: '/kontakt', list: 'r' },
  ])

  const links = computed<NavItem[]>(() => {
    if (!items.value) return []
    return items.value.map((item: any) => {
      return {
        title: item.title,
        slug: item.slug,
        list: item.list,
        active: route.path.indexOf(item.slug) > -1
      }
    })
  })

  const linksLeft = computed<NavItem[]>(() => links.value.filter((item) => item.list === 'l'))
  const linksRight = computed<NavItem[]>(() => links.value.filter((item) => item.list === 'r'))

  return { links, linksLeft, linksRight }
}
