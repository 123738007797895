<script setup lang="ts">
import type { PropType } from 'vue';
import type { NavItem } from '~~/types';

  const props = defineProps({
    items: {
      type: Array as PropType<NavItem[]>,
      default: () => [],
    },
  })
</script>

<template>
  <ul class="flex items-center space-x-5">
    <li v-for="item in props.items" :key="`header_nav_${item?.slug}`" class="relative">
      <nuxt-link 
        :to="item?.slug" 
        class="font-script font-semibold text-3xl tracking-widest hover:text-primary/80" 
        :class="{ 'link-active': item?.active }"
      >
        {{ item?.title }}
      </nuxt-link>
    </li>
  </ul>
</template>
<style scoped>
.link-active {
  color: rgb(var(--color-burgundy-100));
}
</style>