<script setup lang="ts">
import AppHorizontalNav from './AppHorizontalNav.vue'
import AppMobile from './AppMobile.vue'
const { linksLeft, linksRight, links } = useNav()
</script>
<template>
    <div class="flex items-center justify-between space-x-8 bg-burgundy-200 px-2 md:px-0">
      
      <div class="flex-1 hidden md:flex items-center justify-end pt-3">
        <AppHorizontalNav :items="linksLeft" />
      </div>

      <NuxtLink to="/" class="flex-none">
        <img src="/logo-115x56.png" alt="Fotostorie logo" class="h-14" width="115" height="56" srcset="/logo-115x56.png 1w, /logo-173x84.png 2w" sizes="(max-width: 600px) 480px, 800px" />
      </NuxtLink>

      <div class="flex-1 hidden md:flex items-center justify-start pt-3">
        <AppHorizontalNav :items="linksRight" />
      </div>
      
      <div class="flex md:hidden items-center justify-end">
        <AppMobile />
      </div>
    </div>
</template>
<style>
.link-active {
  color: rgb(var(--color-primary-400));
}

.link-active::after {
  content: '';
  position: absolute;
  bottom: -8px;
  width: 50%;
  left: 25%;
  height: 2px;
  background-color: rgb(var(--color-primary-400));
}
</style>